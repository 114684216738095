/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap");
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// *{
//   font-family: 'Vujahday Script', cursive;
// }
.pad-150 {
  padding: 0 150px;
}

ion-searchbar ion-icon {
  right: 16px !important;
  left: auto !important;
}

ion-searchbar {
  // transform: scale(1.2); // Can also scale individually with scaleX() and scaleY()
  margin: 0 auto;
  width: 100%; // To avoid pixelate effect that occurs with scaling
}

ion-toolbar {
  color: #f7a42c;
}

.onboard-main {
  ion-slides {
    .swiper-pagination.swiper-pagination-bullets {
      bottom: 10% !important;
      // left: auto !important;
      // right: auto;
      // text-align: left !important;
    }
  }
}

input:hover {
  outline: none;
}

.toast-class {
  -border-radius: 10px;
}

*:not(mat-icon) {
  font-family: "Quicksand", sans-serif !important;
}

.mat-step-text-label {
  font-weight: 800;
  font-size: 18px;
}

::ng-deep
  .mat-form-field-appearance-outline
  .mat-form-field-outline:not(.mat-form-field-outline-thick) {
  color: #c5c5c5;
}

ion-toolbar {
  padding: 0px 150px;
}

@media only screen and (max-width: 900px) {
  .header-img {
    display: none;
  }
  ion-toolbar {
    padding: 0px 10px;
  }
}

.productlist {
  padding: 0 150px;
}

@media only screen and (max-width: 400px) {
  .productlist {
    padding: 0;
  }
}

.cursor {
  cursor: pointer !important;
}
