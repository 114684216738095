// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #f6a41a;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #f1c16d;
  --ion-color-primary-tint: #ec9600;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  // mat-icon-stepper selected color change
  ::ng-deep .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: #4f1211 !important;
  }

  //input outline color
  ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
    color: r#4f1211ed !important;
    // opacity: 1!important;
  }

  //mat-input focused color
  ::ng-deep
    .mat-form-field-appearance-outline.mat-focused
    .mat-form-field-outline-thick {
    color: #4f1211 !important;
  }

  // mat-input error outline color
  ::ng-deep
    .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
    .mat-form-field-outline-thick {
    color: #4f1211 !important;
    opacity: 0.8 !important;
  }

  // mat-input carent color
  ::ng-deep .mat-input-element {
    caret-color: #4f1211 !important;
  }

  // mat-input error carent color
  ::ng-deep .mat-form-field-invalid .mat-input-element,
  .mat-warn .mat-input-element {
    caret-color: red !important;
  }

  // mat-label normal state style
  ::ng-deep .mat-form-field-label {
    color: rgba(0, 0, 0, 0.6) !important;
    // color: $mainColor!important;
  }

  // mat-label focused style
  ::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
    color: #4f1211 !important;
  }

  // mat-label error style
  ::ng-deep .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #4f1211 !important;
  }
}
